import { SystemProps } from "@chakra-ui/react";

export enum SlideType {
  Image,
  Video,
}

export enum TextContentType {
  Title,
  Content,
  Subtitle,
}

type TImageSlide = {
  type: SlideType.Image;
  url: string;
};

type TVideoSlide = {
  type: SlideType.Video;
  url: string;
};

export type TTextContent = {
  type: TextContentType;
  text: string;
  fontSizeMobile?: number;
  fontSizeDesktop?: number;
  fontColorMobile?: string;
  fontColorDesktop?: string;
};

type TSlideCommon = {
  linkUrl?: string;
  content?: TTextContent[];
};

export type TSlide = {
  desktopUrl?: TImageSlide | TVideoSlide;
  mobileUrl?: TImageSlide | TVideoSlide;
} & TSlideCommon;

export type TPreparedSlide = {
  url: TImageSlide | TVideoSlide;
} & TSlideCommon;

export type TSlideTypeProps = {
  url: string;
  isVisible: boolean;
};

export type TSlideProps = {
  slide: TPreparedSlide;
  isVisible: boolean;
  height: SystemProps["height"];
};

export type TSlideRawOverlayProps = {
  content: string;
};

export type TSlideTextContentProps = {
  content: TTextContent[];
};

export type TCarouselProps = {
  slides: TSlide[];
  autoplaySpeed?: number;
  height?: SystemProps["height"];
  isDesktopVisible?: boolean;
  isMobileVisible?: boolean;
};

export type TCarouselSwiperProps = TCarouselProps & { isMobile: boolean };
