import { FC } from "react";

import { useTranslation } from "next-i18next";
import { Navigation, Pagination, Virtual, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";

import Slide from "./Slide";
import type { TCarouselSwiperProps, TPreparedSlide } from "./types";

const getKey = (i: number): string => `slide-${i}`;

const CarouselSwiper: FC<TCarouselSwiperProps> = ({ isMobile, slides, autoplaySpeed, height }) => {
  const { t } = useTranslation();

  const filteredSlides = slides.reduce<TPreparedSlide[]>((acc, { mobileUrl, desktopUrl, ...slideRest }) => {
    const url = isMobile ? mobileUrl : desktopUrl;
    if (url) {
      acc.push({ ...slideRest, url });
    }
    return acc;
  }, []);

  if (filteredSlides.length === 0) {
    return null;
  }

  return (
    <Swiper
      autoplay={autoplaySpeed ? { delay: autoplaySpeed } : undefined}
      modules={[Navigation, Pagination, Virtual, Autoplay, A11y]}
      slidesPerView={1}
      navigation={slides.length > 1}
      pagination={{ clickable: true }}
      watchSlidesProgress
      loop={slides.length > 1}
      lazy={{ loadPrevNext: true }}
      a11y={{
        prevSlideMessage: t("common-carousel-previous_slide"),
        nextSlideMessage: t("common-carousel-next_slide"),
      }}
    >
      <>
        {filteredSlides.map((slide, index) => (
          <SwiperSlide key={getKey(index)} virtualIndex={index}>
            {({ isVisible }) => <Slide slide={slide} isVisible={isVisible} height={height} />}
          </SwiperSlide>
        ))}
      </>
    </Swiper>
  );
};
export default CarouselSwiper;
