import { FC } from "react";

import Image from "@components/Image";

import type { TSlideTypeProps } from "./types";

const ImageSlide: FC<TSlideTypeProps> = ({ url }) => (
  <Image src={url} layout="fill" objectFit="cover" alt="" priority />
);
export default ImageSlide;
